import '../static/main.css'
import { Elm } from './Main.elm'
import translationsEN from '../public/local/translations.en.json'
import translationUK from '../public/local/translations.uk.json' ;



let translations;
//Selecting the navigator language
if(navigator.language =="uk") {
  translations = translationUK
} else{
  translations = translationsEN

}



// Initializing the app 
                                      
const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags:{ 
    "tr":JSON.stringify(translations),
    "env":{
      "haycap":process.env.ELM_APP_HAYCAP,
      "weetags":process.env.ELM_APP_WEETAGS,
    
       
        }
            },
        })



// Changing the color theme for accessibility

app.ports.selectTh.subscribe(function(data) {

  

  if(data == "dark"){
  document.documentElement.classList.add(data);
 
  }
  else{
    document.documentElement.classList.remove('dark');
    
  }
})

//Unselecting  checkboxes 

app.ports.uncheck.subscribe(function(data) {


  const check  = document.querySelectorAll("#uncheck")

  for(let i = 0  ; i < check.length ; i++){
     check[i].checked = false
  }

  
 
})

// Selecting the language

app.ports.selectLg.subscribe(function (data) {
 



  if (data == 'uk') {
    translations = translationUK
    document.documentElement.setAttribute("lang", 'uk');

    if(window.hbspt) {

     hbspt.forms.create({
      region: "na1",
      portalId: "7434578",
      formId: "1d2c9e7f-dcc6-4238-b7f1-662cc49942e7",
      target: "#contact_form"
    });
  }

  } else {
    translations = translationsEN
    document.documentElement.setAttribute("lang", 'en');
    
    if(window.hbspt) {
    hbspt.forms.create({
      region: "na1",
      portalId:"7434578",
      formId:"92e7ded1-33ad-4df7-8c18-24187cd4ea9c",
      target: "#contact_form"
     });
    }


  }
  app.ports.changeLg.send(JSON.stringify(translations))
})



//Loading hubspot form in Elm


class hubSpotForm extends HTMLElement {

  
  connectedCallback() { 

    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);
   

    script.addEventListener('load' ,() =>
    
    {

      if(window.hbspt) {


        let lang  = document.getElementsByTagName('html')[0].getAttribute('lang');

        

        if(lang == "en") {

          hbspt.forms.create({
            region: "na1",
            portalId:"7434578",
            formId:"92e7ded1-33ad-4df7-8c18-24187cd4ea9c",
            target: "#contact_form"
           });

        } else {


          hbspt.forms.create({
            region: "na1",
            portalId: "7434578",
            formId: "1d2c9e7f-dcc6-4238-b7f1-662cc49942e7",
            target: "#contact_form"
          });



        }

  
        let  container  = document.createElement("div") ;
        container.class = "ml-16" ;
        container.id ="contact_form"
  
        this.append(container)
  
  
      }
  


    })

  
   
  
      
  }
  

}



// Adding custom element for hubpost form on the app

window.customElements.define("hub-spot", hubSpotForm);

